function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT_PATH = '';

export const PATH_AUTH = {
  login: '/login',
  resetPassword: '/reset-password',
};

export const PATH_CMS = {
  root: ROOT_PATH,
  app: path(ROOT_PATH, '/app'),
  user: {
    root: path(ROOT_PATH, '/user'),
    list: path(ROOT_PATH, '/user/list'),
    create: path(ROOT_PATH, '/user/create'),
    import: path(ROOT_PATH, '/user/import'),
    edit: (id: string) => path(ROOT_PATH, `/user/${id}/edit`),
    profile: path(ROOT_PATH, '/user/profile'),
  },
  subscriber: {
    root: path(ROOT_PATH, '/subscriber'),
    list: path(ROOT_PATH, '/subscriber/list'),
    create: path(ROOT_PATH, '/subscriber/create'),
    edit: (id: string) => path(ROOT_PATH, `/subscriber/${id}/edit`),
    profile: path(ROOT_PATH, '/subscriber/profile'),
    listCold: path(ROOT_PATH, '/subscriber/cold/list'),
    viewCold: (params: string) =>
      path(ROOT_PATH, `/subscriber/cold/details?${params}`),
  },
  productB2B: {
    root: path(ROOT_PATH, '/b2b'),
    list: path(ROOT_PATH, '/b2b/list'),
    create: path(ROOT_PATH, '/b2b/create'),
    edit: (id: string) => path(ROOT_PATH, `/b2b/${id}/edit`),
  },
  productB2C: {
    root: path(ROOT_PATH, '/b2c'),
    list: path(ROOT_PATH, '/b2c/list'),
    create: path(ROOT_PATH, '/b2c/create'),
    discount_coupons: path(ROOT_PATH, '/b2c/discount-coupons'),
    product: path(ROOT_PATH, '/b2c/discount-coupons/product'),
    order: path(ROOT_PATH, '/b2c/discount-coupons/order'),
    edit: (id: string) => path(ROOT_PATH, `/b2c/${id}/edit`),
    edit_order_coupon: (id: string) =>
      path(ROOT_PATH, `/b2c/discount-coupons/order/${id}`),
    edit_product_coupon: (id: string) =>
      path(ROOT_PATH, `/b2c/discount-coupons/product/${id}`),
  },
  productStreaming: {
    root: path(ROOT_PATH, '/streaming'),
    list: path(ROOT_PATH, '/streaming/list'),
    create: path(ROOT_PATH, '/streaming/create'),
    edit: (id: string) => path(ROOT_PATH, `/streaming/${id}/edit`),
    vitrine: {
      root: path(ROOT_PATH, '/streaming/vitrine'),
      list: path(ROOT_PATH, '/streaming/vitrine/list'),
      create: path(ROOT_PATH, '/streaming/vitrine/create'),
      edit: (id: string) => path(ROOT_PATH, `/streaming/vitrine/${id}/edit`),
    },
  },
  baseGenerator: {
    root: path(ROOT_PATH, '/base-generator'),
    list: path(ROOT_PATH, '/base-generator/list'),
    create: path(ROOT_PATH, '/base-generator/create'),
  },
  bookClub: {
    root: path(ROOT_PATH, '/book-club'),
    list: path(ROOT_PATH, '/book-club/list'),
    create: path(ROOT_PATH, '/book-club/create'),
    edit: (id: string) => path(ROOT_PATH, `/book-club/${id}/edit`),
  },
  campaign: {
    root: path(ROOT_PATH, '/campaign'),
    list: path(ROOT_PATH, '/campaign/list'),
    create: path(ROOT_PATH, '/campaign/create'),
    edit: (id: string) => path(ROOT_PATH, `/campaign/${id}/edit`),
  },
  promotion: {
    root: path(ROOT_PATH, '/promotion'),
    create: path(ROOT_PATH, '/promotion/create'),
    list: path(ROOT_PATH, '/promotion/list'),
  },
  contributor: {
    root: path(ROOT_PATH, '/contributor'),
    list: path(ROOT_PATH, '/contributor/list'),
    create: path(ROOT_PATH, '/contributor/create'),
    edit: (id: string) => path(ROOT_PATH, `/contributor/${id}/edit`),
  },
  distributor: {
    root: path(ROOT_PATH, '/distributor'),
    list: path(ROOT_PATH, '/distributor/list'),
    create: path(ROOT_PATH, '/distributor/create'),
    edit: (id: string) => path(ROOT_PATH, `/distributor/${id}/edit`),
  },
  imprint: {
    root: path(ROOT_PATH, '/imprint'),
    list: path(ROOT_PATH, '/imprint/list'),
    create: path(ROOT_PATH, '/imprint/create'),
    edit: (id: string) => path(ROOT_PATH, `/imprint/${id}/edit`),
  },
  publisher: {
    root: path(ROOT_PATH, '/publisher'),
    list: path(ROOT_PATH, '/publisher/list'),
    create: path(ROOT_PATH, '/publisher/create'),
    forecasts: path(ROOT_PATH, '/forecast/create'),
    forecast: path(ROOT_PATH, '/forecast/list'),
    edit: (id: string) => path(ROOT_PATH, `/publisher/${id}/edit`),
  },

  priceRange: {
    root: path(ROOT_PATH, '/price-range'),
    list: path(ROOT_PATH, '/price-range/list'),
    create: path(ROOT_PATH, '/price-range/create'),
    edit: (id: string) => path(ROOT_PATH, `/price-range/${id}/edit`),
  },
  plan: {
    root: path(ROOT_PATH, '/plan'),
    list: path(ROOT_PATH, '/plan/list'),
    create: path(ROOT_PATH, '/plan/create'),
    edit: (id: string) => path(ROOT_PATH, `/plan/${id}/edit`),
  },
  carrier: {
    root: path(ROOT_PATH, '/carrier'),
    list: path(ROOT_PATH, '/carrier/list'),
    create: path(ROOT_PATH, '/carrier/create'),
    edit: (id: string) => path(ROOT_PATH, `/carrier/${id}/edit`),
  },
  document: {
    root: path(ROOT_PATH, '/document'),
    faq: {
      root: path(ROOT_PATH, '/document/faq'),
      list: path(ROOT_PATH, '/document/faq/list'),
      create: path(ROOT_PATH, '/document/faq/create'),
      edit: (id: string) => path(ROOT_PATH, `/document/faq/${id}/edit`),
    },
    policy: {
      root: path(ROOT_PATH, '/document/policy'),
      list: path(ROOT_PATH, '/document/policy/list'),
      create: path(ROOT_PATH, '/document/policy/create'),
      edit: (id: string) => path(ROOT_PATH, `/document/policy/${id}/edit`),
    },
    term: {
      root: path(ROOT_PATH, '/document/term'),
      list: path(ROOT_PATH, '/document/term/list'),
      create: path(ROOT_PATH, '/document/term/create'),
      edit: (id: string) => path(ROOT_PATH, `/document/term/${id}/edit`),
    },
  },
  voucher: {
    root: path(ROOT_PATH, '/voucher'),
    list: path(ROOT_PATH, '/voucher/list'),
    create: path(ROOT_PATH, '/voucher/create'),
    edit: (id: string) => path(ROOT_PATH, `/voucher/${id}/edit`),
  },
  track: {
    root: path(ROOT_PATH, '/track'),
    edit: (id: string) => path(ROOT_PATH, `/track/${id}/edit`),
    cold: {
      root: path(ROOT_PATH, '/track/cold'),
      list: path(ROOT_PATH, '/track/cold/list'),
      edit: (id: string) => path(ROOT_PATH, `/track/cold/${id}/edit`),
    },
    hot: {
      root: path(ROOT_PATH, '/track/hot'),
      list: path(ROOT_PATH, '/track/hot/list'),
      edit: (id: string) => path(ROOT_PATH, `/track/hot/${id}/edit`),
    },
    trade: {
      root: path(ROOT_PATH, '/track/trade'),
      list: path(ROOT_PATH, '/track/trade/list'),
      edit: (id: string) => path(ROOT_PATH, `/track/trade/${id}/edit`),
    },
  },
  linkedBooks: {
    root: path(ROOT_PATH, '/linked-books'),
    list: path(ROOT_PATH, '/linked-books/list'),
    create: path(ROOT_PATH, '/linked-books/create'),
    edit: (id: string) => path(ROOT_PATH, `/linked-books/${id}/edit`),
  },
  linkShortner: {
    root: path(ROOT_PATH, '/link-shortner'),
    create: path(ROOT_PATH, '/link-shortner/create'),
  },
  streaming: {
    tracks: {
      root: path(ROOT_PATH, '/streaming/tracks'),
      hot: path(ROOT_PATH, '/streaming/tracks/hot'),
      cold: path(ROOT_PATH, '/streaming/tracks/cold'),
      trade: path(ROOT_PATH, '/streaming/tracks/trade'),
    },
  },
  bundle: {
    root: path(ROOT_PATH, '/bundle'),
    list: path(ROOT_PATH, '/bundle/list'),
    create: path(ROOT_PATH, '/bundle/create'),
    edit: (id: string) => path(ROOT_PATH, `/bundle/${id}/edit`),
    tracks: {
      root: path(ROOT_PATH, '/bundle/tracks'),
      hot: path(ROOT_PATH, '/bundle/tracks/hot'),
      cold: path(ROOT_PATH, '/bundle/tracks/cold'),
      trade: path(ROOT_PATH, '/bundle/tracks/trade'),
    },
  },
  collections: {
    root: path(ROOT_PATH, '/collections'),
    list: path(ROOT_PATH, '/collections/list'),
    create: path(ROOT_PATH, '/collections/create'),
    edit: (id: string) => path(ROOT_PATH, `/collections/${id}/edit`),
  },
  dev_tools: {
    root: path(ROOT_PATH, '/dev-tools'),
    feature_flag: {
      root: path(ROOT_PATH, '/dev-tools/feature-flag'),
    },
  },
};
