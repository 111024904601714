import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class TrackService {
  private baseEndpoint = 'track';

  getAllSugestion = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `suggestion-${this.baseEndpoint}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getSuggestionById = async (
    id: number,
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `suggestion-${this.baseEndpoint}/${id}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  createSuggestion = async (
    data: FormData
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';

    const endpoint = `suggestion-${this.baseEndpoint}`;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  updateSuggestion = async (
    id: number,
    data: FormData
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';

    const endpoint = `suggestion-${this.baseEndpoint}/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getPlans = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}-plan?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (
    id: number,
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  updateName = async (
    id: number,
    name: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}-plan/${id}`;

    const data = { name };

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (
    id: number,
    data: any,
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}?${requestParams}`;

    return Service.post(endpoint, { items: data }) as Promise<
      AxiosResponse<any, any>
    >;
  };

  clone = async (
    id: number,
    trackIdsToReplace: any
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/clone/${id}`;

    const data = { track_plan_id_list: trackIdsToReplace };

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  updateSuggestionOrder = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'application/json';

    const endpoint = `suggestion-${this.baseEndpoint}/reorder`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getAllTrackPlans = async (
    trackType: 'streaming' | 'bundle',
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'application/json';

    const endpoint = `${trackType}/track-plan?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };
}
