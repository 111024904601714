import { Collection, CollectionCreateData } from '@/@types/collection';
import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class CollectionsService {
  private baseEndpoint = 'store/collections';

  create = async (
    data: CollectionCreateData
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'application/json';
    const endpoint = this.baseEndpoint;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (id: number, data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getAll = async (requestParams: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (id: number): Promise<AxiosResponse<Collection, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.get(endpoint) as Promise<AxiosResponse<Collection, any>>;
  };

  deleteById = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.delete(endpoint, {}) as Promise<AxiosResponse<any, any>>;
  };
}
