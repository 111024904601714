import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class SubscriberService {
  private baseEndpoint = 'subscriber';

  subscribeEmail = async (data: {
    email: string;
  }): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/crm/subscribe`;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  unsubscribeEmail = async (data: {
    email: string;
  }): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/crm/unsubscribe`;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getCold = async (requestParams: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/cold?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getColdDetail = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/cold/details?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getAll = async (requestParams: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}?${requestParams}`;
    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (id: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  create = async (data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (data: any, id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  cleanData = async (id: number | string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}/clean-data/false`;

    return Service.put(endpoint, undefined) as Promise<AxiosResponse<any, any>>;
  };

  cleanCache = async (
    id: number | string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}/cache/invalidate`;

    return Service.patch(endpoint, undefined) as Promise<
      AxiosResponse<any, any>
    >;
  };

  disable = async (id: number | string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}/deactivate`;

    return Service.put(endpoint, undefined) as Promise<AxiosResponse<any, any>>;
  };

  deleteByIds = async (idList: number[]): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    const payload = {
      id_list: idList,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };

  unlink = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/unlink/${id}`;

    return Service.put(endpoint, undefined) as Promise<AxiosResponse<any, any>>;
  };

  swapBook = async (
    subscriberId: number,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/swap/${subscriberId}`;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  populateBenefit = async ({
    plan_id,
    id,
    external_code,
    force_activation,
  }: {
    plan_id: number;
    id: string;
    external_code: string | undefined;
    force_activation: boolean;
  }): Promise<AxiosResponse<any, any>> => {
    const externalCode = external_code
      ? `?external_code=${external_code}&`
      : '?';
    const endpoint = `benefit/plan/${plan_id}/subscriber/${id}${externalCode}force_activation=${force_activation}`;

    return Service.post(endpoint, {}) as Promise<AxiosResponse<any, any>>;
  };

  deliverBook = async (
    id: string,
    plan_id: number,
    book_ids: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/delivery/${id}`;
    return Service.post(endpoint, { plan_id, book_ids }) as Promise<
      AxiosResponse<any, any>
    >;
  };

  deliveryPoints = async (
    points: number,
    subscriberId: string | number
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `gamification/${subscriberId}/delivery-points`;

    const data = {
      points,
      expiration_in_days: 90,
    };

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };
}
