export enum Roles {
  ADMIN_EDITORA = 'Admin Editora',
  ADMIN_SKEELO = 'Admin Skeelo',
  ATENDIMENTO = 'Atendimento',
  AUTOR = 'Autor',
  CORP_ADMIN = 'Corp Admin',
  EDITORA_ESCRITA = 'Editora (Escrita)',
  EDITORA_LEITURA = 'Editora Leitura',
  EDITORIAL_SKEELO_AVANCADO = 'Editorial Skeelo Avançado',
  EDITORIAL_SKEELO_BASICO = 'Editorial Skeelo Básico',
  EDITORIAL_SKEELO_INTERMEDIARIO = 'Editorial Skeelo Intermediário',
  LEITURA_SKEELO = 'Leitura Skeelo',
  MARKETING = 'Marketing',
  SUPER_ADMIN = 'Super Admin',
}
