import Service from '@/services/request';
import { AxiosResponse, RawAxiosRequestHeaders } from 'axios';

export class DropdownService {
  retrieveBisacGenres = async (
    queryParams = 'page=1&limit=1000',
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `bisac?${queryParams}`;

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  retrievePartners = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `partner?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrieveTerritories = async (
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'territory';

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  retrievePlanOptions = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `plan/dropdown?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrievePriceRangeOptions = async (
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'price-range/dropdown';

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  retrieveSlugsOptions = async (
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = 'plan/dropdown/slug?limit=1000&page=1';

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };
}
