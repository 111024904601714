import Service from '@/services/request';
import { AxiosResponse, RawAxiosRequestHeaders } from 'axios';

export class TagsService {
  private baseEndpoint = 'tag';

  getAll = async (
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}`;

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };
}
