import { SkeeloApiPagingResponse } from '@/@types/api';
import { Publisher } from '@/@types/publisher';
import Service from '@/services/request';
import { AxiosResponse, RawAxiosRequestHeaders } from 'axios';

import { EditorialGroupService } from './editorial-group';

export class PublisherService extends EditorialGroupService {
  constructor() {
    super('publisher');
  }

  create = async (data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (id: number, data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getAll = async (
    requestParams: string,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<SkeeloApiPagingResponse<Publisher>, any>> => {
    const endpoint = `${this.baseEndpoint}?${requestParams}`;

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  deleteByIds = async (idList: number[]): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    const payload = {
      id_list: idList,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };
}
