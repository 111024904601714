import { APIServiceDefaultParams, SkeeloApiPagingResponse } from '@/@types/api';
import { Coupon, CouponDTO, UpdateActivityBody } from '@/@types/coupon';
import Service from '@/services/request';
import { AxiosResponse, RawAxiosRequestHeaders } from 'axios';

export class CouponService {
  private baseEndpoint = 'store/coupons';

  getAll = async (
    requestParams: APIServiceDefaultParams,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<SkeeloApiPagingResponse<Coupon>>> => {
    const { page, limitPerPage, filters } = requestParams;
    const endpoint = `${this.baseEndpoint}?page=${page || 1}&limit=${
      limitPerPage || 10
    }${filters || ''}`;

    return Service.get(endpoint, headers) as Promise<
      AxiosResponse<SkeeloApiPagingResponse<Coupon>>
    >;
  };

  getById = async (
    id: number,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<Coupon>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.get(endpoint, headers) as Promise<AxiosResponse<Coupon>>;
  };

  create = async (
    requestParams: APIServiceDefaultParams<CouponDTO>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    return Service.post(endpoint, requestParams.body) as Promise<
      AxiosResponse<any, any>
    >;
  };

  update = async (
    requestParams: APIServiceDefaultParams<CouponDTO>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${requestParams?.body?.id}`;

    return Service.put(endpoint, requestParams.body) as Promise<
      AxiosResponse<any, any>
    >;
  };

  updateActivity = async (
    requestParams: APIServiceDefaultParams<UpdateActivityBody>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${requestParams?.body?.id}/activity`;

    return Service.put(endpoint, requestParams.body) as Promise<
      AxiosResponse<any, any>
    >;
  };
}
