import { AuthService } from './auth';
import { BaseTrackService } from './baseTrack';
import { BiService } from './bi';
import { BookClubService } from './book-club';
import { CampaignService } from './campaign';
import { CarrierService } from './carrier';
import { CollectionsService } from './collections';
import { ContributorService } from './contributor';
import { CouponService } from './coupon';
import { DistributorService } from './distributor';
import { DocumentService } from './document';
import { DropdownService } from './dropdown';
import { FeatureFlag } from './feature-flag';
import { ForecastService } from './forecast';
import { ImprintService } from './imprint';
import { LanguageService } from './language';
import { LinkShortnerService } from './link-shortner';
import { LinkedBookService } from './linked-book';
import { PlanService } from './plan';
import { PriceRangeService } from './price-range';
import { ProductService } from './product';
import { PublisherService } from './publisher';
import { SubscriberService } from './subscriber';
import { TagsService } from './tags';
import { TrackService } from './track';
import { UserService } from './user';
import { VoucherService } from './voucher';

export class SkeeloApi {
  auth = new AuthService();
  bi = new BiService();
  bookClub = new BookClubService();
  bundle = new BaseTrackService('bundle');
  campaign = new CampaignService();
  carrier = new CarrierService();
  collections = new CollectionsService();
  contributor = new ContributorService();
  distributor = new DistributorService();
  document = new DocumentService();
  dropdown = new DropdownService();
  featureFlag = new FeatureFlag();
  imprint = new ImprintService();
  language = new LanguageService();
  linkedBook = new LinkedBookService();
  linkShortner = new LinkShortnerService();
  plan = new PlanService();
  priceRange = new PriceRangeService();
  product = new ProductService();
  publisher = new PublisherService();
  store = new BaseTrackService('store');
  streaming = new BaseTrackService('streaming');
  subscriber = new SubscriberService();
  suggestionTrack = new BaseTrackService('suggestion-track');
  tags = new TagsService();
  track = new TrackService();
  user = new UserService();
  voucher = new VoucherService();
  forecast = new ForecastService();
  coupon = new CouponService();
}
