import Service from '@/services/request';
import { AxiosResponse, RawAxiosRequestHeaders } from 'axios';

export class PlanService {
  private baseEndpoint = 'plan';

  getAll = async (
    requestParams: string,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/product?${requestParams}`;

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (
    id: number,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  update = async (id: string, data: any): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';

    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  create = async (data: any): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';

    const endpoint = this.baseEndpoint;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getBenefitPlans = async (
    requestParams?: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `benefit/plans?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getActiveBase = async (
    id: string,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/database/${id}`;

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };
}
