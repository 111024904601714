import Service from '@/services/request';
import { AxiosResponse, RawAxiosRequestHeaders } from 'axios';

export class ContributorService {
  private baseEndpoint = 'contributor';

  create = async (data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (id: number, data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getAll = async (
    requestParams: string,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}?${requestParams}`;

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (
    id: number,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  deleteByIds = async (idList: number[]): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    const payload = {
      id_list: idList,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };
}
