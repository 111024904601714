import { createContext, ReactNode, useContext, useState } from 'react';

interface DirtyFormProviderProps {
  children: ReactNode;
}

type DirtyFormContextData = {
  dirty: boolean;
  onChangeDirty: (dirty: boolean) => void;
};

const DirtyFormContext = createContext<DirtyFormContextData>(
  {} as DirtyFormContextData
);

export function DirtyFormProvider({
  children,
}: DirtyFormProviderProps): JSX.Element {
  const [dirty, setDirty] = useState(false);

  const onChangeDirty = (isDirty: boolean) => {
    setDirty(isDirty);
  };

  return (
    <DirtyFormContext.Provider value={{ dirty, onChangeDirty }}>
      {children}
    </DirtyFormContext.Provider>
  );
}

export function useDirtyForm(): DirtyFormContextData {
  const context = useContext(DirtyFormContext);
  return context;
}
