// routes
import { Roles } from './auth/auth-context/enums/Roles';
import { PATH_CMS } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const FIREBASE_API = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APPID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.AUTH0_CLIENT_ID,
  domain: process.env.AUTH0_DOMAIN,
};

export const { MAPBOX_API } = process.env;

// ROOT PATH AFTER LOGIN SUCCESSFUL
const PATH_AFTER_LOGIN_DEFAULT = PATH_CMS.productB2B.list;
export const PATH_AFTER_LOGIN = {
  default: PATH_AFTER_LOGIN_DEFAULT,
  [Roles.ADMIN_EDITORA]: null,
  [Roles.ADMIN_SKEELO]: PATH_AFTER_LOGIN_DEFAULT,
  [Roles.ATENDIMENTO]: PATH_AFTER_LOGIN_DEFAULT,
  [Roles.AUTOR]: PATH_AFTER_LOGIN_DEFAULT,
  [Roles.CORP_ADMIN]: PATH_AFTER_LOGIN_DEFAULT,
  [Roles.EDITORA_ESCRITA]: PATH_CMS.productB2C.list,
  [Roles.EDITORA_LEITURA]: PATH_CMS.productB2C.list,
  [Roles.EDITORIAL_SKEELO_AVANCADO]: PATH_AFTER_LOGIN_DEFAULT,
  [Roles.EDITORIAL_SKEELO_BASICO]: PATH_AFTER_LOGIN_DEFAULT,
  [Roles.EDITORIAL_SKEELO_INTERMEDIARIO]: PATH_CMS.productB2C.list,
  [Roles.LEITURA_SKEELO]: PATH_AFTER_LOGIN_DEFAULT,
  [Roles.MARKETING]: PATH_AFTER_LOGIN_DEFAULT,
  [Roles.SUPER_ADMIN]: PATH_AFTER_LOGIN_DEFAULT,
};

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
