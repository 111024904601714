import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class LanguageService {
  private baseEnpoint = 'language';

  getAll() {
    return Service.get(this.baseEnpoint) as Promise<AxiosResponse<any, any>>;
  }
}
