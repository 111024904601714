import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class BiService {
  private baseEndpoint = 'bi';

  generateBase = async (data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/mkt-database`;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  retrieveBases = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `files/${this.baseEndpoint}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };
}
