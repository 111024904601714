import Service from '@/services/request';
import { AxiosResponse, RawAxiosRequestHeaders } from 'axios';

export class BookClubService {
  private baseEndpoint = 'club';

  create = async (data: any): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';
    const endpoint = `${this.baseEndpoint}/create`;
    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (
    id: number,
    data: FormData
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';

    const endpoint = `${this.baseEndpoint}/single/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getAll = async (requestParams: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/list?${requestParams}`;
    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (
    id: number,
    headers?: Partial<RawAxiosRequestHeaders>
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/single/${id}`;
    return Service.get(endpoint, headers) as Promise<AxiosResponse<any, any>>;
  };

  deleteById = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/single/${id}`;

    const payload = {
      id,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };

  updateContent = async (
    clubId: number,
    id: number,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${clubId}/book/${id}`;
    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  deleteMaterial = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/material/${id}`;

    const payload = {
      id,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };

  deleteContent = async (
    id: number,
    clubId: number
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${clubId}/book/${id}`;

    const payload = {
      id,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };

  createMaterial = async (
    data: any,
    club_id: number
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';

    const endpoint = `${this.baseEndpoint}/${club_id}/material`;
    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getMaterialById = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/material/${id}`;
    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  updateMaterial = async (
    id: number,
    data: FormData
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';

    const endpoint = `${this.baseEndpoint}/single/material/${id}`;
    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getAllContents = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}/material/list`;
    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  createContent = async (
    data: any,
    club_id: number
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${club_id}/book`;
    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  createClue = async (
    data: any,
    club_id: number
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${club_id}/clue`;
    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getAllClues = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}/clue`;
    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getClueById = async (
    id: number,
    clubId: number
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${clubId}/clue/${id}`;
    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  updateClue = async (
    clubId: number,
    id: number,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${clubId}/clue/${id}`;
    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  deleteClue = async (
    id: number,
    clubId: number
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${clubId}/clue/${id}`;

    const payload = {
      id,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };
}
