import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class BaseTrackService {
  private baseEndpoint = '';

  constructor(baseEndpoint: string) {
    this.baseEndpoint = baseEndpoint;
  }

  generateSheet = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    Service.service.defaults.responseType = 'blob';

    const endpoint = `generate-sheets?${requestParams}`;

    return Service.get(endpoint);
  };

  retrieveAllTrackPlans = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/track-plan?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getTrackById = async (
    id: number,
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/track/${id}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrieveTrackBooks = async (
    id: number,
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/track/${id}/books?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  updateTrack = async (
    id: number,
    data: any,
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}?${requestParams}`;

    return Service.post(endpoint, { items: data }) as Promise<
      AxiosResponse<any, any>
    >;
  };

  cloneTracks = async (
    id: number,
    trackIdsToReplace: any
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/clone/${id}`;

    const data = { track_plan_id_list: trackIdsToReplace };

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  findAll = async (requestParams: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrieveAllBooksByTrackId = async (
    id: string | number,
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/track/${id}/books?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrieveAllBooks = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  moveAllToTrack = async (
    id: string | number,
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/track/${id}/move-all${requestParams}`;

    return Service.put(endpoint, {}) as Promise<AxiosResponse<any, any>>;
  };
}
