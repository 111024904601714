import { ForecastResponse } from '@/components/forecast/form/schema';
import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class ForecastService {
  private endpoint = 'publisher/forecasts';
  private bookEndpoint = 'book';

  getAll = async (requestParams: string): Promise<AxiosResponse<any, any>> => {
    const url = `${this.endpoint}?${requestParams}`;

    return Service.get(url) as Promise<AxiosResponse<ForecastResponse, any>>;
  };

  getById = async (id: string | string[]): Promise<AxiosResponse<any, any>> => {
    const url = `${this.endpoint}/${id}`;

    return Service.get(url) as Promise<AxiosResponse<ForecastResponse, any>>;
  };

  create = async (data: any): Promise<AxiosResponse<any, any>> => {
    const url = `${this.endpoint}`;

    return Service.post(url, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (
    data: any,
    id: string | string[]
  ): Promise<AxiosResponse<any, any>> => {
    const url = `${this.endpoint}/${id}`;

    return Service.put(url, data) as Promise<AxiosResponse<any, any>>;
  };

  setPriority = async (data: any): Promise<AxiosResponse<any, any>> => {
    const url = `${this.endpoint}/order-priorities`;

    return Service.post(url, data) as Promise<AxiosResponse<any, any>>;
  };

  getForecastBook = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const url = `${this.bookEndpoint}?${requestParams}`;

    return Service.get(url) as Promise<AxiosResponse<any, any>>;
  };
}
