import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class FeatureFlag {
  private baseEndpoint = 'feature-flag';

  get = async (): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  update = async (versions: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    return Service.post(endpoint, { versions }) as Promise<
      AxiosResponse<any, any>
    >;
  };
}
