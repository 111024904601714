import { MetabookInfoResponse } from '@/sections/@cms/product/form/metabookInfoSchema';
import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class ProductService {
  private baseEndpoint = 'book';
  private metabookEndpoint = 'metabook/complete-book-info';

  create = async (data: any): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';
    const endpoint = this.baseEndpoint;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (
    id: number,
    data: FormData
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'application/epub+zip';

    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  getAll = async (requestParams: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  deleteByIds = async (idList: number[]): Promise<AxiosResponse<any, any>> => {
    const endpoint = this.baseEndpoint;

    const payload = {
      id_list: idList,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };

  getAllAudiobookChapters = async (
    id: number
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}/chapters`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  createChapter = async (
    id: number,
    data: FormData
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'application/epub+zip';

    const endpoint = `${this.baseEndpoint}/${id}/chapters`;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  updateChapter = async (
    id: number,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}/chapters`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  deleteChapter = async (
    id: number,
    idList: number[]
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}/chapters`;

    const payload = {
      id_list: idList,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };

  createPromotion = async (
    distributor_id: number,
    data: FormData
  ): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers['Content-Type'] = 'multipart/form-data';

    const endpoint = `${this.baseEndpoint}/sale/${distributor_id}`;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  retrievePromotions = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/sale?${requestParams}`;
    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  retrievePromotionBooks = async (
    campaign: any,
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/sale/${campaign}?${requestParams}`;
    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  syncProductWithStore = async (
    id: number,
    operation: 'publish' | 'unpublish'
  ): Promise<[AxiosResponse<any, any>, AxiosResponse<any, any>]> => {
    Service.service.defaults.headers['Content-Type'] = 'application/json';
    const endpointShopify = `${this.baseEndpoint}/shopify/sync`;
    const endpointNewStore = `${this.baseEndpoint}/store/sync`;

    const data = {
      id,
      operation,
    };

    const requests = [
      Service.post(endpointShopify, data),
      Service.post(endpointNewStore, data),
    ];

    return Promise.all(requests) as Promise<
      [AxiosResponse<any, any>, AxiosResponse<any, any>]
    >;
  };

  retrieveAllTrackProducts = async (
    requestParams: string
  ): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/tracks?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getInfoBookByISBN = async (isbn: string) => {
    const endpoint = `${this.metabookEndpoint}/${isbn}`;

    return Service.get(endpoint) as Promise<
      AxiosResponse<MetabookInfoResponse>
    >;
  };
}
