import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class AuthService {
  private endpoint = 'admin';

  login = async (data: any): Promise<AxiosResponse<any, any>> => {
    const url = `${this.endpoint}/login`;

    return Service.post(url, data) as Promise<AxiosResponse<any, any>>;
  };

  forgotPassword = async (data: any): Promise<AxiosResponse<any, any>> => {
    const url = `${this.endpoint}/forgot-password`;

    return Service.post(url, data) as Promise<AxiosResponse<any, any>>;
  };

  resetPassword = async (
    data: any,
    token: string
  ): Promise<AxiosResponse<any, any>> => {
    const url = `${this.endpoint}/change-password/${token}`;

    return Service.put(url, data) as Promise<AxiosResponse<any, any>>;
  };
}
