import { AxiosResponse } from 'axios';

export abstract class EditorialGroupService {
  protected baseEndpoint: string;

  constructor(baseEndpoint?: string) {
    this.baseEndpoint = baseEndpoint || '';
  }

  abstract create(data: any): Promise<AxiosResponse<any, any>>;
  abstract update(id: number, data: any): Promise<AxiosResponse<any, any>>;
  abstract getAll(requestParams: string): Promise<AxiosResponse<any, any>>;
  abstract getById(id: number): Promise<AxiosResponse<any, any>>;
  abstract deleteByIds(idList: number[]): Promise<AxiosResponse<any, any>>;
}
