type Tokens = {
  sb_token: string;
  refresh_token?: string;
  token: string;
};

const COOKIE_DOMAIN =
  process.env.NODE_ENV === 'production' ? '.skeelo.com' : 'localhost';

export class Session {
  static getTokens(): Tokens {
    const cookie: { [key: string]: string } = {};

    document.cookie.split(';').forEach(el => {
      const [k, v] = el.split('=');
      cookie[k.trim()] = v;
    });

    return {
      token: cookie.token,
      sb_token: cookie.sb_token,
      refresh_token: cookie.refresh_token,
    };
  }

  static removeSBToken(): void {
    document.cookie = `sb_token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${COOKIE_DOMAIN};path=/`;
    document.cookie = `refresh_token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${COOKIE_DOMAIN};path=/`;
  }

  static setCookie = (cookieName: string, cookieValue: string): void => {
    document.cookie = `${cookieName}=${cookieValue};domain=${COOKIE_DOMAIN};path=/;secure`;
  };
}

export default new Session();
