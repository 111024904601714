import Service from '@/services/request';
import { AxiosResponse } from 'axios';

export class CampaignService {
  private baseEndpoint = 'landing-page';

  getAll = async (requestParams: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}?${requestParams}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  getById = async (id: number): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.get(endpoint) as Promise<AxiosResponse<any, any>>;
  };

  create = async (data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}`;

    return Service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  update = async (id: number, data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}/${id}`;

    return Service.put(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };

  delete = async (idList: number[]): Promise<AxiosResponse<any, any>> => {
    const endpoint = `${this.baseEndpoint}`;

    const payload = {
      id_list: idList,
    };

    return Service.delete(endpoint, payload) as Promise<
      AxiosResponse<any, any>
    >;
  };
}
