import { PATH_AUTH } from '@/routes/paths';
import Service from '@/services/request';
import _ from 'lodash';

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const isValidToken = (token: string) => {
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);

  if (_.isNil(decoded.exp)) {
    return true;
  }

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    console.error('Token expired');

    localStorage.removeItem('auth.token');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

export const setSession = (token: string | null, user: any) => {
  if (token) {
    localStorage.setItem('auth.token', token);

    if (user) {
      localStorage.setItem('auth.user', JSON.stringify(user));
    }

    Service.service.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('auth.token');
    localStorage.removeItem('auth.user');

    delete Service.service.defaults.headers.Authorization;
  }
};
