import { AxiosResponse } from 'axios';

import { Service } from './request';

const BASE_URL = process.env.NEXT_PUBLIC_SKEELO_EBOOK_API as string;
const service = new Service(BASE_URL);

export class LinkShortnerService {
  shorten = async (data: any): Promise<AxiosResponse<any, any>> => {
    const endpoint = `url/shorten`;
    return service.post(endpoint, data) as Promise<AxiosResponse<any, any>>;
  };
}
